.section
  @apply pt-[64px] md:pt-[48px] lg:pt-[120px]
  @apply pb-[128px] md:pb-[120px] lg:pb-[176px]

  .shape
    @apply select-none

  .description
    @apply text-center mt-7 md:mt-6 lg:mt-12 lg:font-neutral

  .button
    @apply inline-block w-[180px] md:w-full mt-7 md:mt-6 lg:mt-10
